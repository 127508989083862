import axios from "axios"


let apiHost = window.location.hostname
if(apiHost.startsWith('www.')){
  apiHost = window.location.hostname.substring('www.'.length)
}

let apiUrl = apiHost

let apiPort = window.location.port

if(apiUrl === 'localhost') { 
  apiPort = '8099'
}else{
  apiUrl = 'api.' + apiUrl
}

let apiWsProtocol = 'ws'

if (window.location.protocol === 'https:'){
  apiWsProtocol = 'wss'
}

let baseURL = `//${apiUrl}:${apiPort}/api/v1`


if(window.location.port === ''){
  baseURL = `//${apiUrl}/api/v1`
  apiPort = null
}

try {
  const healthCheck = await axios.get(`//${apiUrl}:${apiPort}/a`)

  console.log(`Healthcheck URL: ${healthCheck}`)

  if(healthCheck.data['success']){
    console.log('Healthcheck: OK!')
  }
} catch(e) {
  console.log(e)
}

export const api = axios.create({
  baseURL: baseURL
});

export function getUserType(){
  let userType = localStorage.getItem("@production-dagobah-web-user-type")
  return userType != null ? JSON.parse(userType) : '';
}

export const apiConfig = {
  apiHost, apiUrl, apiPort, apiWsProtocol
}