import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import Balloon from '../../../components/Balloon';
import { IconContext } from "react-icons";
import { useNavigate } from 'react-router-dom';
import * as FiSend from "react-icons/fi";
import { api } from './../../../api';
import {
  Container, ContainerFile, Left, Right, SubTitle, ContainerSelectParams,
  InputSelectParams, Title, Wrapper, HeaderFile, BoxContainer,
  TreeContainer, TitleStructure, RadioContainer, DetailDiv, CardContainer, TextInfo,
  LogContainer, ActionContainer, RadioInput, RadioLabel, RadioLine, Response, FlexContainer,
  BarContainer, Info, UpdIcon, CloseIcon, UpdateParam, DetailProgress, StatusDetail, Both, Progress, Spinner, EditStatus, ContainerUpdate, ResultReportLabel, InputNumberContainer
} from './styles';
import { useParams } from 'react-router-dom';
import ButtonActions from '../../../components/Button/ButtonActions';
import timer from '../../../assets/timer-logo.png';
import steps from '../../../assets/steps-logo.png';
import { FaPlay } from "react-icons/fa";
import { IoMdPause } from "react-icons/io";
import { IoStop } from "react-icons/io5";
import Success from '../../../components/Modal/Success';
import Loader from '../../../components/Loader';
import DeviceCard from '../../../components/Cards/ConexaoDispositivoCard/index';
import ErrorLog from '../../../components/Modal/ModalSync/ErrorLog';
import { TestTree } from '../../../components/Tree/TestTree';
import { Timer } from '../../../components/Timer';
import ModalParams from '../../../components/Modal/ModalParams';
import RemoveUpdate from '../../../components/Modal/ModalParams/RemoveUpdate';
import { LogCards } from '../../../components/LogCards';
import soundAlert from '../../../assets/aviso.mp3'
import useSound from 'use-sound';
import LogInput from './../../../components/Input/LogInput';
import { useConfirmExit, useMediaQuery } from 'react-haiku';
import { changeStartStatusBySelection, copyProperties, createExecution } from './executionFlow';
import { EXECUTION_STATUS } from './executionStatus';
import moment from 'moment'
import intl from "react-intl-universal";
import ModalReconnect from '../../../components/Modal/ModalReconnect';


const locales = {
  "pt-BR": require("../../../locales/pt-BR.json"),
  "en-US": require("../../../locales/en-US.json"),
};

if (!localStorage.getItem("lang")) {
  localStorage.setItem('lang', 'pt-BR');
}

intl.init({
  currentLocale: localStorage.getItem("lang")
  , locales
  , fallbackLocale: 'pt-BR'
})

let timeoutId = null;
let intervalId = null;
let timeoutMs = null;
let pause = false
let previousMapSize = 0;
let stopBtnFlag = false;
let executionID = null;
let executionCounter = 0;
let stepsSelectedCounter = 0;
let isFromWaitUC = false;
const defaultOption = intl.get("test-execution.select-parameter-file")
const param_reference = {}
const DEFAULT_TIMEOUT = 20
const DEFAULT_DELAY = 10
const TEMPLATE_KEY_NO = "${no}-${no_loop}"
export default function ExecProj() {
  const navigate = useNavigate();
  const { ID, method } = useParams();
  const [play, { stop }] = useSound(soundAlert, {
    volume: 0.5,
    playbackRate: 0.5
  });

  const [params, setParams] = useState([]);
  const [idParam, setIdParam] = useState();
  const [pickParam, setPickParam] = useState('');
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [openConnection, setOpenModalConnection] = useState(false);
  const [openDisconnection, setOpenModalDisconnection] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [isFlagFilter, setFlagFilter] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [socketData, setSocketData] = useState([]);
  const [userConfirm, setUserConfirm] = useState('');
  const [inputData, setInputData] = useState('');
  const [playControlButton, setPlayControlButton] = useState(false);
  const [pauseControlButton, setPauseControlButton] = useState(false);
  const [stopControlButton, setStopControlButton] = useState(false);
  const [reportControlButton, setReportControlButton] = useState(false);
  const [timeoutInput, setTimeoutInput] = useState(true);
  const [openReconnect, setOpenReconnect] = useState(false);
  const [openParamsModal, setOpenParamsModal] = useState(false);
  const [openRemoveParams, setOpenRemoveParams] = useState(false);
  const [openGenerateReport, setOpenGenerateReport] = useState(false);
  const [deviceConnected, setDeviceConnected] = useState(false);
  const [selectedParams, setSelectedParams] = useState(defaultOption);
  const [editJson, setEditJson] = useState(null);
  const timeoutMap = useRef({})
  const [headerCount, setHeaderCount] = useState({
    'project': 0
    , 'script': 0
    , 'feature': 0
    , 'scenario': 0
    , 'step': 0
  });

  const [timeoutValue, setTimeoutValue] = useState(DEFAULT_TIMEOUT);
  const [loopSleep, setLoopSleep] = useState(null);
  const [endExecution, setEndExecution] = useState(false);
  const [originalJson, setOriginalJson] = useState([]);
  const [json, setJson] = useState('');
  const [projectId, setProjectId] = useState(null);
  const [stepList, setStepList] = useState([]);

  const [currentStep, setCurrentStep] = useState({});
  const [lastExecutionDone, setLastExecutionDone] = useState(0);
  const [stepsDoneCounter, setStepsDoneCounter] = useState(0);

  const [executionStatus, setExecutionStatus] = useState(EXECUTION_STATUS.STS_MAIN_FIRST_STATUS);
  const [lastExecutionStatus, setLastExecutionStatus] = useState(EXECUTION_STATUS.STS_MAIN_FIRST_STATUS);

  const [flagReportLoading, setFlagReportLoading] = useState(false);

  const [filter1disabled, setFilter1disabled] = useState(true);
  const [filter2disabled, setFilter2disabled] = useState(true);
  const [execution, setExecution] = useState(false);
  const [radioSelection, setRadioSelection] = useState(0);
  const [createReport, setCreateReport] = useState(true);
  const [failFilter, setFailFilter] = useState(false);
  const [notExecutedFilter, setNotExecutedFilter] = useState(false);
  const [notConcludedFilter, setNotConcludedFilter] = useState(false);
  const [passedFilter, setPassedFilter] = useState(false);
  const [notSelectedFilter, setNotSelectedFilter] = useState(false);
  const [failResult, setFailResult] = useState(false);
  const [notExecResult, setNotExecResult] = useState(false);
  const [notConcResult, setNotConcResult] = useState(false);
  const [notPassResult, setNotPassResult] = useState(false);
  const [notSelectResult, setNotSelectResult] = useState(false);
  const [interceptItem, setInterceptItem] = useState(undefined);
  const [updateHour, setUpdateHour] = useState(null);
  const [updateDate, setUpdateDate] = useState(null);
  const [disableUpd, setDisableUpd] = useState(false);
  const [initTagOption, setInitTagOpt] = useState(false);
  const [userPaused, setUserPaused] = useState(false);
  const [executedSteps, setExecutedSteps] = useState([]);
  const [paramFlag, setParamFlag] = useState(undefined);
  const [filteredSteps, setFilterSteps] = useState('');
  const [pending, setPending] = useState(true);
  const [projectStatus, setProjectStatus] = useState("");
  const [filterJson, setFilterJson] = useState("");
  const [fetchError, setFetchError] = useState(false);
  const [fetchMsg, setFetchMsg] = useState("");
  const [loopCount, setLoopCount] = useState(0);



  useEffect(() => {
    executionID = null;
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (openReconnect) {
      timerComponentRef.current.pauseTimer();
    }
    else if (executionStatus !== EXECUTION_STATUS.STS_MAIN_FIRST_STATUS) {
      timerComponentRef.current.initTimer();
      setPlayControlButton(false);
      setPauseControlButton(true);
      setStopControlButton(true);
      setReportControlButton(false);
    }
  }, [openReconnect]);

  const CustomLoader = () => (
    <div style={{ padding: '24px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Spinner />
      <div>{intl.get('loader.loading')}</div>
    </div>
  );

  function getProjectStatus(status) {
    setProjectStatus(status);
  };

  function tryReconnect() {
    executeCurrentStep()
  }

  function updateHeaderCount(structure) {
    let newHeaderCount = Object.assign({}, headerCount)
    newHeaderCount['project'] = countJsonItemsByTag(structure, 'scripts')
    newHeaderCount['script'] = countJsonItemsByTag(structure, 'features')
    newHeaderCount['feature'] = countJsonItemsByTag(structure, 'scenarios')
    newHeaderCount['scenario'] = countJsonItemsByTag(structure, 'steps')
    newHeaderCount['step'] = stepsSelectedCounter
    setHeaderCount(newHeaderCount)
  }

  const isEdging = useMediaQuery('(max-width: 1600px)');

  const timerComponentRef = useRef(null);

  useConfirmExit(playControlButton && editJson); // before execution with updates
  useConfirmExit(execution); // during execution

  async function fetchParams() {
    const response = await api.get(`/projects/${ID}/params`);
    setParams(response.data);
  };

  const getData = () => {
    api.get(`/projects/tree?ids=${ID}`)
      .then(response => {
        const data = response.data;
        const checkedStructure = putIndexStruct(data);
        const selectedSteps = getSteps(data);
        updateHeaderCount(checkedStructure)
        setOriginalJson(JSON.stringify(checkedStructure));
        setJson(checkedStructure);
        setStepList(selectedSteps)
        stepsSelectedCounter = selectedSteps.length;
      })
      .catch(error => {
        setFetchError(true)
        setFetchMsg(error.response.data.detail)
      });
  };

  const getDataReExec = () => {
    api.get(`projects/report/${ID}`)
      .then(response => {
        const data = response.data;
        let structure = data.structure;
        let params = data.params;

        const checkedStructure = putIndexStruct(structure);
        const selectedSteps = getSteps(structure);
        setPending(false)
        updateHeaderCount(checkedStructure);
        setProjectId(data.id);
        setOriginalJson(JSON.stringify(checkedStructure));
        setJson(checkedStructure);
        setStepList(selectedSteps);
        stepsSelectedCounter = selectedSteps.length;
        setParams([{
          "id": params.id,
          "name": params.name,
          "content": params.json,
          "id_origin": idParam
        }]);
      })
      .catch(error => {
        setPending(false)
        setFetchError(true)
        setFetchMsg(error.response.data.detail)
      });
  };


  useMemo(() => {
    const jsonFiltered = JSON.parse(JSON.stringify(json)) //global variable

    console.log('selectedParams ', selectedParams)
    if (selectedParams === null) {
      setSelectedParams(defaultOption)
      if (executionCounter < 1) {
        getData()
      }
      return
    }

    if (jsonFiltered === '' || !paramFlag) {
      setFilterJson(json)
      return
    }

    let isFiltered = false;

    let toDelete = []

    const hasEveryArray = (baseArray, array) => {
      return baseArray.every(item => array.includes(item))
    }

    jsonFiltered.scripts.forEach((script) => {
      script.features.forEach((feature) => {

        const featureFlags = feature.flags || []
        const validFeature = hasEveryArray(paramFlag, featureFlags)

        if (!validFeature) {
          isFiltered = true
          toDelete.push([jsonFiltered, script, feature])
        } else {
          feature.scenarios.forEach((scenario) => {
            const scenarioFlags = scenario.flags || []
            const validScenario = hasEveryArray(paramFlag, scenarioFlags)
            if (!validScenario) {
              isFiltered = true
              toDelete.push([jsonFiltered, script, feature, scenario])
            }
          })
        }
      })
    })

    while (toDelete.length > 0) {
      const objectToDelete = toDelete.pop()
      if (objectToDelete.length === 4) {
        removeFromList(objectToDelete[2].scenarios, objectToDelete[3]);
      }
      else if (objectToDelete.length === 3) {
        removeFromList(objectToDelete[1].features, objectToDelete[2])
      }

      if (objectToDelete[2].scenarios.length === 0) {
        removeFromList(objectToDelete[1].features, objectToDelete[2])
      }
      if (objectToDelete[1].features.length === 0) {
        removeFromList(objectToDelete[0].scripts, objectToDelete[1])
      }
    }

    if (paramFlag && paramFlag.length > 0 && isFiltered) {
      setStepList(getSteps(jsonFiltered))
      stepsSelectedCounter = getSteps(jsonFiltered).length;
      setFilterSteps(stepsSelectedCounter)
      setFilterJson(jsonFiltered)
      setFlagFilter(true)
    } else {
      setFilterJson(json)
    }
  }, [json, paramFlag]);


  function removeFromList(listItems, toRemoveObject) {
    const index = listItems.indexOf(toRemoveObject);
    listItems.splice(index, 1);
  }

  useEffect(() => {

    if (method === 'exec') {
      fetchParams();
      if (executionCounter < 1) { getData(); }
    }

    if (method === 'reexec') {
      getDataReExec();
    }

  }, [ID, method, editJson]);


  useEffect(() => {
    if (execution) {
      setFilter1disabled(true);
      setFilter2disabled(true);
    } else {
      setFilter1disabled(false);
      setFilter2disabled(false);
    }
  }, [execution]);

  useEffect(() => {
    if (interceptItem) {
      const filaShifted = interceptItem;

      if (filaShifted['user_confirm'] && filaShifted['status'] !== 'NÃO EXECUTADO') {
        waitUserResponse()
        setExecutionStatus(EXECUTION_STATUS.STS_ALT_CONFIRM_VALUE)
      } else {

        let newJson = Object.assign({}, json);

        let newStatus = filaShifted['status']

        updatingStepStatus(newJson, filaShifted['no'], newStatus);

        setJson(newJson);

        if (!stepList.length && loopCount < 1) {
          setExecutionStatus(EXECUTION_STATUS.STS_MAIN_END_EXECUTION)
        } else {
          if (stepList.length === 1 && loopCount < 1) {
            setExecutionStatus(EXECUTION_STATUS.STS_MAIN_END_EXECUTION)
          }
          setExecutionStatus(EXECUTION_STATUS.STS_MAIN_GET_NEXT_STEP)
        }
      }
    }
  }, [interceptItem]);


  function onGenerateReport() {
    if (getPercent(stepsDoneCounter, stepsSelectedCounter) === 100 && createReport) {
      navigate(`/reports/data/${executionID}`)
    }
  }

  function updatingStepStatus(newJson, no, newStatus) {
    let parents = [];

    newJson['scripts'].forEach((script) => {
      script['features'].forEach((feature) => {
        feature['scenarios'].forEach((scenario) => {
          scenario['steps'].forEach((step) => {
            if (step['no'] === no) {
              updateStructStatus(step, newStatus);
              parents = [[scenario, 'steps'], [feature, 'scenarios'], [script, 'features'], [newJson, 'scripts']];
            }
          });
        });
      });
    });

    parents.forEach((parent) => {
      const struct = parent[0];
      const children = parent[1];

      let listDistinctStatus = [...new Set(struct[children].map(child => child.executionStatus))];

      if (listDistinctStatus.includes('EXECUTANDO')) {
        updateStructStatus(struct, 'EXECUTANDO');
        return;
      }

      if (listDistinctStatus.includes('AGUARDANDO') && listDistinctStatus.length > 1) {
        updateStructStatus(struct, 'EXECUTANDO');
        return;
      }

      if (listDistinctStatus.includes(undefined) && listDistinctStatus.length > 1) {
        updateStructStatus(struct, 'EXECUTANDO');
        return;
      }

      if (listDistinctStatus.includes('FALHA')) {
        updateStructStatus(struct, 'FALHA');
        return;
      }


      if (listDistinctStatus.includes('NÃO CONCLUSIVO')) {
        updateStructStatus(struct, 'NÃO CONCLUSIVO');
        return;
      }

      if (listDistinctStatus.includes('NÃO EXECUTADO') && listDistinctStatus.includes('PASSOU')) {
        updateStructStatus(struct, 'NÃO CONCLUSIVO');
        return;
      }

      if (listDistinctStatus.includes('NÃO EXECUTADO')) {
        updateStructStatus(struct, 'NÃO EXECUTADO');
        return;
      }

      if (listDistinctStatus.includes('PASSOU')) {
        updateStructStatus(struct, 'PASSOU');
        return;
      }

    });
  }

  useEffect(() => {
    updateHeaderCount(filterJson)
  }, [paramFlag, filterJson, selectedParams])

  function isWaitingInput() {
    return executionStatus === EXECUTION_STATUS.STS_ALT_CONFIRM_VALUE || executionStatus === EXECUTION_STATUS.STS_ALT_WAIT_INPUT;
  }

  function getSteps(data) {
    if (Array.isArray(data))
      return data.map(item => {
        const iterScripts = script => {
          const iterScenarios = scenario => scenario.steps
          const iterFeatures = feature => feature.scenarios.map(iterScenarios).flat()
          return script.features.map(iterFeatures).flat()
        }
        return item.scripts.map(iterScripts).flat()
      }).flat()
    return data['scripts'].map(script => {
      return script['features'].map(feature => {
        return feature['scenarios'].map(scenario =>
          scenario['steps'].flat()
        ).flat()
      }).flat()
    }).flat()
  }

  if (isShowLoader) {
    return <Loader isShow={isShowLoader} />
  }

  function hasChecked(json) {
    let output = false;
    if (json) {
      json.scripts.forEach((script) => {
        script.features.forEach((feature) => {
          feature.scenarios.forEach((scenario) => {
            scenario.steps.forEach((step) => {
              if (step.checked === true) {
                output = true
              }
            })
          }
          )
        }
        )
      }
      )
    }
    return output;
  }

  useEffect(() => {
    if (executionStatus === EXECUTION_STATUS.STS_ALT_CONFIRM_VALUE) {
      play()
    }
    if (pause) { return; }
    if (stopBtnFlag) {
      setStepList([])
      setExecutionStatus(EXECUTION_STATUS.STS_MAIN_FIRST_STATUS)
      return;
    }

    if (executionStatus === EXECUTION_STATUS.STS_MAIN_FIRST_STATUS) {
      if (json !== '') {
        const steps = getSteps(json).filter((item) => item.checked === true)
        updateHeaderCount(json)
        setStepList(steps)
      }
    }
    else if (executionStatus === EXECUTION_STATUS.STS_MAIN_GET_NEXT_STEP) {
      getNextStep()
    } else if (executionStatus === EXECUTION_STATUS.STS_MAIN_EXECUTE_STEP) {
      executeCurrentStep()
    } else if (executionStatus === EXECUTION_STATUS.STS_MAIN_END_EXECUTION) {
      setEndExecution(true);
      finishExecution()
      timerComponentRef.current.pauseTimer()
    }
  }, [executionStatus])

  async function getNextStep() {
    if (loopCount > 0) {
      setExecutionStatus(EXECUTION_STATUS.STS_MAIN_EXECUTE_STEP)
    }
    else if (stepList.length > 0 && !openDisconnection) {
      let newStepList = Object.assign([], stepList)
      const step = newStepList.shift()
      console.log('newStepList ', newStepList)
      console.log('step ', step)
      updatingStepStatus(json, step.no, 'EXECUTANDO')
      setCurrentStep(step)
      for (let param of step.params) {
        if ((param.type === 'IN' || param.type === 'IN/OUT' )&& param_reference[param.value_in]) {
          param.value_in = param_reference[param.value_in];
        }
      }
      let hasUserInput = false
      for (let param of step.params) {
        if (!isNaN(param['value_in']))
          continue;
        hasUserInput = param['value_in'].toLowerCase() === '@user_input' || param['value_out'].toLowerCase() === '@user_input'
        if (hasUserInput) {
          play()
          break
        };
      }
      if (step['is_loop'] === true) {
        console.log('entrou aqi1')
        setStepList(newStepList);
        setLoopCount(step['loop_count'])
      } else {
        console.log('entrou aqi2')
        setStepList(newStepList);
      }
      if (hasUserInput) {
        console.log('entrou aqi4')
        setExecutionStatus(EXECUTION_STATUS.STS_ALT_WAIT_INPUT)
      } else {
        console.log('entrou aqi5')
        setExecutionStatus(EXECUTION_STATUS.STS_MAIN_EXECUTE_STEP)
      }
      console.log('entrou aqi fim')
    }
  }

  async function executeCurrentStep() {
    if (stopBtnFlag) {
      console.log(`1. [${new Date().toISOString()}] Msg API command ID ${currentStep.db_id} | STOP`)
      return
    }
    if ('no' in currentStep) {
      const step = Object.assign({}, currentStep)
      const ms = step['loop_sleep'] || 1;
      const timeoutSeconds = ('timeout_step' in currentStep ? currentStep['timeout_step'] : timeoutValue)
      const timeoutMs = (timeoutSeconds * 1000) + DEFAULT_DELAY
      const auxExecutionCount = executionCounter
      if (step['is_loop'] === true) {
        await new Promise(r => {
          setLoopSleep(ms * 1000)
          const timeoutSleep = setTimeout(() => {
            setLoopSleep(null)
            clearTimeout(timeoutSleep)
            r()
          }, ms * 1000)
        });
        if (stopBtnFlag) {
          return
        }
        step['no_loop'] = step['loop_count'] - loopCount
        const key = TEMPLATE_KEY_NO.replace("${no}", step['no']).replace("${no_loop}", step['no_loop'])
        timeoutMap.current[key] = setTimeout(() => { createTimeout(step, auxExecutionCount) }, timeoutMs)
        console.log(`0. [${new Date().toISOString()}] TIMEOUT start  ${currentStep.db_id}`)
      } else {
        console.log(`0. [${new Date().toISOString()}] TIMEOUT start  ${currentStep.db_id}`)
        timeoutMap.current[step['no']] = setTimeout(() => { createTimeout(step, auxExecutionCount) }, timeoutMs)
        timeoutMap.current = Object.assign({}, timeoutMap.current)
      }
      step['timeout_step'] = timeoutSeconds
      api.post(`projects/exec/${executionID}/${inputData}`, step)
        .then((response) => {
          console.log(`1. [${new Date().toISOString()}] Msg API command ID ${currentStep.db_id}`)
          setOpenReconnect(false)
          clearInterval(intervalId)
          if (step['is_loop'] === true) {
            if (step['no_loop'] === 0) {
              setStepsDoneCounter((previous) => previous + 1)
            }
            setLoopCount(previous => previous - 1)
          } else {
            setStepsDoneCounter((previous) => previous + 1)
          }
        })
        .catch((error) => {
          if (error.response.status >= 400) {
            let notExecutedObject = {
              "id": step['db_id'],
              "type": "step",
              "cmd": error.response.data.detail,
              "cmd_response": error.response.data.detail,
              "conn_id": "1672eb",
              "no": step['no'],
              "status": "NÃO EXECUTADO",
              "user_confirm": false
            }
            if (step['is_loop'] === true) {
              if (step['no_loop'] === 0) {
                setStepsDoneCounter((previous) => previous + 1)
              }
              setLoopCount(previous => previous - 1)
            } else {
              setStepsDoneCounter((previous) => previous + 1)
            }
            setSocketData(previous => {
              return [...previous, notExecutedObject]
            })
            setInterceptItem(notExecutedObject)
            clearTimeoutFrom(notExecutedObject)
          } else {
            setDeviceConnected(false)
            setOpenReconnect(true)
          }
        })
    }
  }

  function openConnectionModal() {
    setOpenModalConnection(true);
  };

  function closeConnectionModal(e) {
    e.preventDefault()
    setOpenModalConnection(false);
  };

  function openDisconnectionModal() {
    setOpenModalDisconnection(true);
  };

  function closeDisconnectionModal(e) {
    e.preventDefault();
    setOpenModalDisconnection(false);
  };

  function closeErrorModal(e) {
    e.preventDefault();
    setOpenError(false);
  };

  function closeErrorFetch(e) {
    e.preventDefault();
    setFetchError(false);
  };

  function openModalParams() {
    setOpenParamsModal(true);
    setDisableUpd(false);
  };

  function closeModalParams(e) {
    e.preventDefault();
    setOpenParamsModal(false);
  };

  function closeRemoveParamsModal(e) {
    e.preventDefault();
    setOpenRemoveParams(false);
  }

  function closeReconnect(e) {
    e.preventDefault();
    setOpenReconnect(false);
  }

  function closeGenerateReportModal(e) {
    e.preventDefault();
    setOpenGenerateReport(false);
  };

  function inputChangeHandler(value) {
    setInputData(value)
  }

  function countJsonItemsByTag(structure, innerTag) {
    let object = null;
    let output = 0;
    if (Array.isArray(structure)) {
      object = structure[0]
    } else if (structure === '') {
      return 0;
    } else {
      object = structure
    }
    if (innerTag.toLowerCase() === 'scripts') {
      return object['checked'] === true ? 1 : 0;
    }
    object['scripts'].forEach((scripts) => {
      if (innerTag in scripts && scripts['checked'] === true) {
        output++;
      } else {
        scripts['features'].forEach((feature) => {
          if (innerTag in feature && feature['checked'] === true) {
            output++;
          } else {
            feature['scenarios'].forEach((scenario) => {
              if (innerTag in scenario && scenario['checked'] === true) {
                output++;
              } else {
                scenario['steps'].forEach((step) => {
                  if (innerTag in step && step['checked'] === true) {
                    output++;
                  }
                })
              }
            })
          }
        })
      }
    })
    return output;
  }

  function createTimeout(step, executionCount) {
    console.log(`2. [${new Date().toISOString()}] Timeout ID ${step['db_id']}`)
    if (executionCount !== executionCounter || endExecution === true) {
      return;
    }
    let notExecutedObject = {
      "id": step['db_id'],
      "type": "step",
      "cmd": "Timeout!",
      "cmd_response": "Timeout!",
      "conn_id": "1672eb",
      "no": step['no'],
      "status": "NÃO EXECUTADO",
      "user_confirm": false
    }
    if ('no_loop' in step) {
      notExecutedObject['no_loop'] = step['no_loop']
    }
    if (!stopBtnFlag && !(socketData.some(item => item.no === step.no))) {
      setSocketData(previous => {

        return [...previous, notExecutedObject]
      })
      setInterceptItem(notExecutedObject)
      clearTimeoutFrom(notExecutedObject)
    }
  }

  const onClickPause = (userIteration = true) => {
    if (!pause) {
      pause = true
      setLastExecutionStatus(executionStatus)
      setExecutionStatus(EXECUTION_STATUS.STS_ALT_PAUSE_EXECUTION)
      setPlayControlButton(false)
      setPauseControlButton(false)
      timerComponentRef.current.pauseTimer();
      setPlayControlButton(true)
      setUserPaused(userIteration)
    }
  }

  function onClickStop() {
    pause = false
    stopBtnFlag = true
    setEndExecution(false)
    setFlagReportLoading(false)
    finishExecution(true)
    let remaningStepList = stepList;
    remaningStepList.push(currentStep)
    remaningStepList = remaningStepList.map((item) => {
      return {
        'no': item.no
        , 'user_confirm': 'user_confirm' in item && item.user_confirm === true
        , 'cmd': 'NÃO EXECUTADO'
        , 'status': 'NÃO EXECUTADO'
      };
    });
    setLoopCount(0)
    setStepsDoneCounter(0)
    setLastExecutionDone(0)
    setStepList([])
    setSocketData([])
    setCurrentStep({})
    let newJson = Object.assign({}, json);
    newJson.executionStatus = ''
    newJson.scripts.forEach((script) => {
      script.executionStatus = ''
      script.features.forEach((feature) => {
        feature.executionStatus = ''
        feature.scenarios.forEach((scenario) => {
          scenario.executionStatus = ''
          scenario.steps.forEach((step) => {
            step.executionStatus = ''
          })
        })
      })
    })
    setJson(newJson)
    setParamFlag(undefined);
    setOpenReconnect(false)
    Object.keys(timeoutMap.current).forEach((id) => {
      const toDelete = { 'no': id }
      clearTimeoutFrom(toDelete)
    })
    timeoutMap.current = {}
    clearInterval(intervalId);
    timerComponentRef.current.clearTimer()
  }

  async function onClickPlay() {
    if (hasChecked(json) && playControlButton) {
      stopBtnFlag = false;
      if (!pause) {
        executionCounter += 1
        setEndExecution(false)
        setLastExecutionDone(0)
        setStepsDoneCounter(0)
        timerComponentRef.current.clearTimer()
        setSocketData([])
        timeoutMap.current = {}
        executeSteps()
      } else {
        if (currentStep.is_loop != true) {
          setStepsDoneCounter(prev => prev - 1)
        }
        setPlayControlButton(false)
        setTimeoutInput(false)
        setPauseControlButton(true)
        setStopControlButton(true)
        setReportControlButton(false)
        resumeExecution();
      }
      timerComponentRef.current.initTimer()
    }
  }

  function resumeExecution(isEnd = false) {
    pause = false;
    if (executionStatus === EXECUTION_STATUS.STS_ALT_CONFIRM_VALUE) {
      if (!stepList.length && isEnd) {
        setExecutionStatus(EXECUTION_STATUS.STS_MAIN_END_EXECUTION);
      } else {
        setExecutionStatus(EXECUTION_STATUS.STS_MAIN_GET_NEXT_STEP);
        setPlayControlButton(false);
        setPauseControlButton(true);
        setStopControlButton(true);
        setReportControlButton(false);
      }
    }
    else {
      setExecutionStatus(lastExecutionStatus);
    }
  };


  function putIndexStruct(jsonStruct) {
    let count = 1;
    const putTrueIfChecked = (structureItem) => {
      if (!('checked' in structureItem)) {
        structureItem['checked'] = true;
      }
      structureItem['executionStatus'] = '';
    }
    const output = Array.isArray(jsonStruct) ? jsonStruct : [jsonStruct]
    return output.map((project) => {
      project['no'] = count++;
      putTrueIfChecked(project)
      project['scripts'] = project['scripts'].map((script) => {
        script['no'] = count++;
        putTrueIfChecked(script)
        script['features'] = script['features'].map((feature) => {
          feature['no'] = count++;
          putTrueIfChecked(feature)
          feature['scenarios'] = feature['scenarios'].map((scenario) => {
            scenario['no'] = count++;
            putTrueIfChecked(scenario)
            scenario['steps'] = scenario['steps'].map((step) => {
              step['no'] = count++;
              putTrueIfChecked(step)
              return step;
            });
            return scenario;
          });
          return feature;
        });
        return script;
      });
      return project;
    })[0];
  }

  const executeSteps = async () => {
    setExecution(true);
    setPlayControlButton(false)
    setTimeoutInput(false)
    setStopControlButton(true)
    setReportControlButton(false)
    setPauseControlButton(true)

    let data = { success: false }
    let requestBody = customizeParams();

    if (method === 'exec') {
      data = await createExecution(ID, executionID, requestBody);
    } else {
      data = await createExecution(projectId, executionID, requestBody);
    }

    if (data.success || executionID) {

      let currentStructure = isFlagFilter ? filterJson : json;
      let newProject = Object.assign({}, currentStructure);

      const transformStructToStartExecution = (structItem, backendStruct) => {
        changeStartStatusBySelection(structItem)
        copyProperties(backendStruct, structItem)
      }

      let backendProject = data.json;
      transformStructToStartExecution(newProject, backendProject)

      newProject.scripts.forEach((script, indexScript) => {
        let backendScript = backendProject['scripts'][indexScript]
        transformStructToStartExecution(script, backendScript)

        script.features.forEach((feature, indexFeature) => {
          let backendFeature = backendScript['features'][indexFeature]
          transformStructToStartExecution(feature, backendFeature)

          feature.scenarios.forEach((scenario, indexScenario) => {
            let backendScenario = backendFeature['scenarios'][indexScenario]
            transformStructToStartExecution(scenario, backendScenario)

            scenario.steps.forEach((step, indexStep) => {
              let backendStep = backendScenario['steps'][indexStep]
              transformStructToStartExecution(step, backendStep)
            })
          })
        })
      })

      setJson(newProject)
      executionID = executionID ? executionID : data['id'];
      if (stepList.length < 1) {
        const executingSteps = getSteps(newProject).filter((structStep) => structStep['checked'])
        setStepList(executingSteps)
        stepsSelectedCounter = executingSteps.length
        setStepsDoneCounter(0)
      }

      setExecutionStatus(EXECUTION_STATUS.STS_MAIN_GET_NEXT_STEP)
      setFailFilter(false);
      setNotExecutedFilter(false);
      setNotConcludedFilter(false);
      setPassedFilter(false);
      setNotSelectedFilter(false);
    } else {
      setOpenError(true)
      setErrorTitle('Não foi possível executar o teste! Tente novamente mais tarde')
      setErrorMsg('')
      setPlayControlButton(false)
      setPauseControlButton(false)
      setStopControlButton(false)
      setReportControlButton(false)
      timerComponentRef.current.clearTimer()
    }
  }

  const waitUserResponse = () => {
    setPlayControlButton(false)
    isFromWaitUC = true
    onClickPause();
  };

  const handleSelectTreeItem = (isCustom = false) => {
    const steps = getSteps(filterJson).filter((item) => item.checked)
    stepsSelectedCounter = steps.length;
    updateHeaderCount(filterJson);
    setStepList(steps)
    if (isCustom) {
      setRadioSelection(0);
      setFailFilter(false);
      setNotExecutedFilter(false);
      setNotConcludedFilter(false);
      setPassedFilter(false);
      setNotSelectedFilter(false);
    }
  }

  useEffect(() => {
    if (!userPaused) {
      if (Object.keys(timeoutMap.current).length > 1 && !pause) {
        onClickPause(false)
      }

      if (Object.keys(timeoutMap.current).length === 0 && pause) {
        onClickPlay()
      }
    }
  }, [timeoutMap.current])


  const interceptEventSocketData = async (eventData) => {
    const intcpData = JSON.parse(eventData.data);
    if (stopBtnFlag) {
      console.log(`2. [${new Date().toISOString()}] Msg websocket command ID ${intcpData.id} | STOP`)
      return;
    } else {
      console.log(`2. [${new Date().toISOString()}] Msg websocket command ID ${intcpData.id}`)
    }
    if (intcpData.type !== 'test') {
      const key = 'no_loop' in intcpData ? TEMPLATE_KEY_NO.replace("${no}", intcpData['no']).replace("${no_loop}", intcpData['no_loop']) : intcpData['no']
      if (!(key in timeoutMap.current)) {
        console.log(`2. [${new Date().toISOString()}] Dropping packet ${intcpData.id}`)
        return
      }
      if ('no_loop' in intcpData && intcpData['user_confirm'] !== true && intcpData.status === 'PASSOU') {
        setLoopCount(0)
      }
      setSocketData(previous => {
        if (previous.map(item => {
          let output = item.id + ''
          if (!isNaN(item.no_loop)) {
            output = item.id + '-' + item.no_loop
          }
          return output
        })
          .find((item) => item === key)) {
          return [...previous]
        } else {
          return [...previous, intcpData]
        }
      })
      setInterceptItem(intcpData);
      if (intcpData.value_out && intcpData.value_out.startsWith('&')) {
        param_reference[intcpData.value_out] = intcpData.cmd_response
      }
      intcpData.params.forEach(item => {
        if (item.hasOwnProperty('received')) {
          param_reference[`&${item.name}`] = item.received
        }
      })
    }
    clearTimeoutFrom(intcpData);
    clearInterval(intervalId);
  };

  const handleParamSelection = (e) => {
    setPickParam(e.target.value)
    setIdParam(e.target.value)

    if (e.target.value !== defaultOption) {
      setInitTagOpt(false)
      setSelectedParams(params[e.target.selectedIndex - 1].content)
      setParamFlag(params[e.target.selectedIndex - 1].content.flags || undefined);
    }
    if (e.target.value === defaultOption) {
      setInitTagOpt(true)
      setSelectedParams(null);
      setParamFlag(undefined);
    }
  };

  const userResponseHandler = (e) => {
    e.preventDefault();
    setUserConfirm(e.target.value);
  };

  function clearTimeoutFrom(intcpData) {
    const key = 'no_loop' in intcpData ? TEMPLATE_KEY_NO.replace("${no}", intcpData['no']).replace("${no_loop}", intcpData['no_loop']) : intcpData['no']
    clearTimeout(timeoutMap.current[key])
    console.log(`0. [${new Date().toISOString()}] TIMEOUT clean  ${intcpData['id']}`)
    delete timeoutMap.current[key];
    timeoutMap.current = Object.assign({}, timeoutMap.current)
  }

  const renderStepsLeft = useCallback(() => {
    return (
      <h3 style={{ fontSize: 16 }}>
        {stepsDoneCounter}/{stepsSelectedCounter}
      </h3>
    )
  }, [stepsDoneCounter, endExecution])

  function getPercent(stepsExecuted, AllSteps) {
    if (AllSteps === 0)
      return 0
    let output = (stepsExecuted * 100) / AllSteps
    output = Math.ceil(output)
    return output
  }

  const onSubmitUserResponse = async (e) => {
    e.preventDefault();
    if (execution && isWaitingInput()) {
      let endFlag = false
      if (executionStatus === EXECUTION_STATUS.STS_ALT_CONFIRM_VALUE) {
        let newStatus = userConfirm === '1' ? 'PASSOU' : 'FALHA'
        if (currentStep['is_loop'] === true && newStatus === 'PASSOU') {
          setLoopCount(0)
        }
        if ((currentStep['is_loop'] === true && loopCount < 1) || currentStep['is_loop'] != true) {
          endFlag = true
        }
        let newJson = Object.assign({}, json);
        await api.put(`projects/exec/${currentStep['db_id']}/status`, {
          'new_status': newStatus
          , 'user_confirm': userConfirm
        })
        stop();
        updatingStepStatus(newJson, currentStep['no'], newStatus);
        setJson(newJson);
        socketData[socketData.length - 1]['user_confirm_status'] = newStatus
        pause = false
        timerComponentRef.current.initTimer();
        resumeExecution(endFlag);
      }
      else if (executionStatus === EXECUTION_STATUS.STS_ALT_WAIT_INPUT) {
        let indexUserInput = [];
        let newCurrentStep = Object.assign({}, currentStep)
        newCurrentStep.params.forEach((param, index) => {
          let type = param.type === 'IN' ? 'value_in' : 'value_out'
          if (param[type].toLowerCase() === '@user_input') {
            indexUserInput.push(index)
            stop()
          }
        });
        if (indexUserInput.length) {
          let firstUserInputParam = newCurrentStep.params[indexUserInput.shift()]
          if (firstUserInputParam['value_in'].toLowerCase() === '@user_input') {
            firstUserInputParam['value_in'] = userConfirm
          } else {
            firstUserInputParam['value_out'] = userConfirm
          }
        }
        setCurrentStep(newCurrentStep)
        if (indexUserInput.length > 0) {
          setExecutionStatus(EXECUTION_STATUS.STS_ALT_WAIT_INPUT)
        } else {
          resumeExecution();
          setExecutionStatus(EXECUTION_STATUS.STS_MAIN_EXECUTE_STEP)
        }
      }
      isFromWaitUC = false;
      setUserConfirm('');
    }
  };

  const resultParams = (state) => {
    setEditJson(state);
    setParamFlag(state.flags)
  };

  function customizeParams() {
    let requestBody = {};

    if (selectedParams !== undefined && !initTagOption) {
      requestBody['id'] = idParam
    }

    if (editJson != undefined) {
      requestBody['jsonEdit'] = editJson
    }

    if (isFlagFilter) {
      requestBody['newStructure'] = filterJson
    }

    if (method === 'reexec') {
      requestBody['reportId'] = ID;
    }

    requestBody['createReport'] = createReport;

    requestBody['timeout'] = timeoutValue || DEFAULT_TIMEOUT;

    return requestBody;
  }

  function finishExecution(stopClicked = false) {
    updateStatusStepsLeft(stopClicked);
    setExecution(false);
    setPlayControlButton(true);
    setTimeoutInput(true);
    setPauseControlButton(false);
    setStopControlButton(false);
    updateExecutionFilterStatus();
    setExecutionStatus(EXECUTION_STATUS.STS_MAIN_FIRST_STATUS)
  };

  async function updateStatusStepsLeft(stopClicked) {
    if (stopClicked === true)
      return
    if (method === 'reexec') {
      setReportControlButton(true);
      return
    }
    setFlagReportLoading(true)
    let newJson = Object.assign({}, json)
    const getUpdateStatusRequestBody = (struct) => {
      let newStatus = struct.executionStatus
      if (struct.executionStatus === 'AGUARDANDO') {
        struct.executionStatus = 'NÃO EXECUTADO';
      } else if (struct.executionStatus === 'EXECUTANDO') {
        struct.executionStatus = 'NÃO EXECUTADO';
      } else {
        struct.executionStatus = newStatus;
      }
      return {
        id: struct.db_id
        , newStatus: newStatus
      }
    }
    let structs = [getUpdateStatusRequestBody(newJson)]

    newJson.scripts.forEach((script) => {
      structs.push(getUpdateStatusRequestBody(script))
      script.features.forEach((feature) => {
        structs.push(getUpdateStatusRequestBody(feature))
        feature.scenarios.forEach((scenario) => {
          structs.push(getUpdateStatusRequestBody(scenario))
          scenario.steps.forEach((step) => {
            structs.push(getUpdateStatusRequestBody(step))
          })
        })
      })
    })

    structs = structs.filter((struct) => struct.newStatus === 'NÃO SELECIONADO' || struct.newStatus === 'AGUARDANDO' || struct.newStatus === 'NÃO EXECUTADO')

    await api.put(`/projects/exec/status`, { 'batch': structs })
    setReportControlButton(true);
    setFlagReportLoading(false)
    setJson(newJson)
  }

  function getReportDisabled() {
    if (reportControlButton === true) {
      return false
    }
    return true;
  }

  function getReportButtonText() {
    let style = (intl.get("test-execution.report"))
    if (flagReportLoading === false && reportControlButton === false) {
      style = (intl.get("test-execution.report"))
    } else if (flagReportLoading && reportControlButton === false) {
      style = (intl.get("test-execution.report-loading"))
    }
    return style
  }

  function updateExecutionFilterStatus() {
    let scenarios = [];
    json.scripts.forEach((script) => {
      script.features.forEach((feature) => {
        scenarios = scenarios.concat(feature.scenarios);
      });
    });

    scenarios = Array.from(new Set(scenarios.map(scenario => scenario.executionStatus)));
    if (scenarios.filter(status => status === 'FALHA').length > 0)
      setFailResult(true);
    if (scenarios.filter(status => status === 'NÃO EXECUTADO').length > 0)
      setNotExecResult(true);
    if (scenarios.filter(status => status === 'NÃO CONCLUSIVO').length > 0)
      setNotConcResult(true);
    if (scenarios.filter(status => status === 'PASSOU').length > 0)
      setNotPassResult(true);
    if (scenarios.filter(status => status === 'NÃO SELECIONADO').length > 0)
      setNotSelectResult(true);
  }

  function updateStructStatus(obj, newStatus) {
    obj['executionStatus'] = newStatus;
  }

  useEffect(() => {
    const date = new Date();
    setUpdateHour(date.toLocaleTimeString())
    setUpdateDate(date)
  }, [editJson]);

  const selectInputRef = useRef(defaultOption);

  const deleteUpdParam = () => {
    setPickParam(selectInputRef.current[0].value)
    setSelectedParams(defaultOption)
    setEditJson();
    setParamFlag(undefined);
  };

  const confirmGenerateReport = (e) => {
    onGenerateReport();
  };

  const validatingParamJson = () => {
    if (editJson) {
      return (
        <ContainerUpdate>
          <IconContext.Provider
            value={{ color: 'green' }}>
            <FiSend.FiSend style={{ cursor: 'pointer', fontSize: '1.7vh' }} />
          </IconContext.Provider>
          <EditStatus children={
            `Editado em: ${moment(updateDate).format('DD/MM/YYYY')} às ${updateHour}`
          } />
        </ContainerUpdate>
      )
    }
    return null
  };

  const translateExecutionStatus = (executionStatus) => {
    switch (executionStatus) {
      case 'PASSOU':
        return intl.get("test-execution.passed-on");
      case 'FALHA':
        return intl.get("test-execution.fail");
      case 'NÃO EXECUTADO':
        return intl.get("test-execution.not-executed");
      case 'NÃO CONCLUSIVO':
        return intl.get("test-execution.not-completed");
      default:
        return '';
    }
  }

  function getTimeoutBackground() {
    if (!timeoutInput) return 'rgba(0,0,0,0.075)'
    return 'rgba(255,255,255,0.5)'
  }

  useEffect(() => {
    if (endExecution) {
      setLastExecutionDone(stepsSelectedCounter)
    }
  }, [endExecution, lastExecutionDone])

  return (
    <Container id='container'>
      <Wrapper>
        {method === 'exec' ? <Title>{intl.get("test-execution.test-execution")}</Title> : <Title>{intl.get("test-execution.test-rerun")}</Title>}
        <Both>
          <Left id='left-content-container'>
            <BoxContainer>
              <DeviceCard
                reconnect={openReconnect}
                deviceConnected={deviceConnected}
                onData={interceptEventSocketData}
                onInputChange={inputChangeHandler}
                onConnectOk={() => {
                  setDeviceConnected(true)
                  if (openReconnect || executionStatus !== EXECUTION_STATUS.STS_MAIN_FIRST_STATUS) {
                    let ms = timeoutMs || timeoutValue || DEFAULT_TIMEOUT;
                    if ((ms / 1000) < 1) {
                      ms = ms * 1000;
                    }
                    intervalId = setInterval(tryReconnect, ms)
                  } else {
                    openConnectionModal()
                    setPlayControlButton(true)
                    setPauseControlButton(false)
                    setStopControlButton(false)
                    setReportControlButton(false)
                  }
                }}
                onDisconnectOk={() => {
                  finishExecution(true)
                  setLastExecutionDone(0)
                  setExecution(false)
                  setStepsDoneCounter(0)
                  setDeviceConnected(false)
                  openDisconnectionModal()
                  setPlayControlButton(false)
                  setPauseControlButton(false)
                  setStopControlButton(false)
                  setReportControlButton(false)
                  clearTimeout(timeoutId)
                  timerComponentRef.current.clearTimer()
                }}
                onDeviceNotFound={() => {
                  setOpenError(true)
                  setErrorTitle(intl.get("test-execution.device-not-found"))
                  setErrorMsg('')
                  setPlayControlButton(false)
                  setPauseControlButton(false)
                  setStopControlButton(false)
                  setReportControlButton(false)
                }}
                onErrorConnect={() => {
                  if (!openReconnect) {
                    setOpenError(true)
                    setErrorTitle(intl.get("test-execution.failed-to-connect-device"))
                    setErrorMsg('')
                    setPlayControlButton(false)
                    setPauseControlButton(false)
                    setStopControlButton(false)
                    setReportControlButton(false);
                  }
                }}
              />
              <ContainerFile>
                <SubTitle>{intl.get("test-execution.parameters-configuration")}</SubTitle>
                <ContainerSelectParams>
                  <InputSelectParams
                    value={pickParam}
                    onChange={handleParamSelection}
                    ref={selectInputRef}
                    disabled={method === 'reexec' || execution}>
                    <option
                      value={method === 'reexec' ? params[0]?.name : defaultOption}
                      key={method === 'reexec' ? params[0]?.id : defaultOption}
                      hidden={method === "reexec" ? true : false}
                    >
                      {method === 'reexec' ? params[0]?.name : defaultOption}
                    </option>
                    {params.map((item, index) => (
                      <option
                        key={index}
                        value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </InputSelectParams>
                  {selectedParams != defaultOption
                    ?
                    <>
                      {initTagOption ?
                        <IconContext.Provider value={{ color: "#7e7e7e" }}>
                          <UpdIcon disabled />
                        </IconContext.Provider>
                        : (method === 'reexec' || execution) ?
                          <IconContext.Provider value={{ color: "#7e7e7e" }}>
                            <UpdIcon disabled />
                          </IconContext.Provider>
                          : <UpdIcon onClick={openModalParams} />
                      }
                      {initTagOption ?
                        <IconContext.Provider value={{ color: "#7e7e7e" }}>
                          <CloseIcon disabled />
                        </IconContext.Provider>
                        : !editJson
                          ?
                          <IconContext.Provider value={{ color: "#7e7e7e" }}>
                            <CloseIcon disabled />
                          </IconContext.Provider>
                          : method === 'reexec' || execution ?
                            <IconContext.Provider value={{ color: "#7e7e7e" }}>
                              <CloseIcon disabled />
                            </IconContext.Provider>
                            : <CloseIcon onClick={() => setOpenRemoveParams(true)} />
                      }
                    </>
                    :
                    <>
                      <IconContext.Provider value={{ color: "#7e7e7e" }}>
                        <UpdIcon disabled />
                      </IconContext.Provider>
                      <IconContext.Provider value={{ color: "#7e7e7e" }}>
                        <CloseIcon disabled />
                      </IconContext.Provider>
                    </>
                  }
                </ContainerSelectParams>
                <UpdateParam>{validatingParamJson()}</UpdateParam>
              </ContainerFile>
            </BoxContainer><TreeContainer>
              <FlexContainer>
                <TitleStructure>{intl.get("test-execution.project-structure")}</TitleStructure>
                <div style={
                  {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}>
                  <p>{intl.get('test-execution.timeout')}</p>
                  <InputNumberContainer
                    type="number"
                    placeholder={`${DEFAULT_TIMEOUT}`}
                    value={timeoutValue}
                    onChange={(e) => { setTimeoutValue(Number(e.target.value)) }}
                    disabled={!timeoutInput}
                    style={{
                      backgroundColor: getTimeoutBackground()
                      , marginLeft: 8
                    }}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20, alignItems: 'center' }}>
                  <p style={{ marginRight: 7, fontSize: 14, fontWeight: 600 }}>{intl.get("test-execution.generate-report")}</p>
                  <input type='checkbox' checked={createReport} onChange={() => setCreateReport(!createReport)} disabled={execution} />
                </div>
              </FlexContainer>
              <HeaderFile>
                <Balloon type="project" color="#65a1c2" title="Project">
                  <span style={{ fontSize: 20, fontWeight: 500 }}>{headerCount['project']}</span>
                </Balloon>
                <Balloon type="script" color="#82d0cf" title="Script">
                  <span style={{ fontSize: 20, fontWeight: 500 }}>{headerCount['script']}</span>
                </Balloon>
                <Balloon type="feature" color="#ac91e0" title="Feature">
                  <span style={{ fontSize: 20, fontWeight: 500 }}>{headerCount['feature']}</span>
                </Balloon>
                <Balloon type="scenario" color="#e4b46f" title="Scenario" >
                  <span style={{ fontSize: 20, fontWeight: 500 }}>{headerCount['scenario']}</span>
                </Balloon>
                <Balloon type="step" color="#ed92b1" title="Step" >
                  <span style={{ fontSize: 20, fontWeight: 500 }}>{headerCount['step']}</span>
                </Balloon>
              </HeaderFile>
              <RadioContainer>
                <p>{intl.get("test-execution.to-execute")}</p>
                <RadioInput style={{}}>
                  <input value={radioSelection} type={'radio'} id="todos" name="Todos" disabled={filter1disabled} onChange={() => setRadioSelection(1)} checked={radioSelection === 1 ? true : false} />
                  <RadioLabel htmlFor="todos">{intl.get("test-execution.all")}</RadioLabel>
                </RadioInput>
                <RadioInput>
                  <input value={radioSelection} type={'radio'} id="userConfirm" name="userConfirm" disabled={filter1disabled} onChange={() => setRadioSelection(2)} checked={radioSelection === 2 ? true : false} />
                  <RadioLabel htmlFor="userComfirm">{intl.get("test-execution.with-user-confirm")}</RadioLabel>
                </RadioInput>
                <RadioInput>
                  <input value={radioSelection} type={'radio'} id="semUserComfirm" name="semUserComfirm" disabled={filter1disabled} onChange={() => setRadioSelection(3)} checked={radioSelection === 3 ? true : false} />
                  <RadioLabel htmlFor="semUserComfirm">{intl.get("test-execution.no-user-confirm")}</RadioLabel>
                </RadioInput>
              </RadioContainer>
              <RadioLine />
              {
                !endExecution ? null :
                  <RadioContainer>
                    <p>{intl.get("test-execution.check-the-status-of-scenarios-for-re-execution")}</p>
                    <RadioInput style={{}}>
                      <input
                        type={'checkbox'}
                        id="fail"
                        name="Falha"
                        disabled={!failResult ? true : false}
                        checked={failFilter}
                        onClick={() => setFailFilter(!failFilter)}
                      />
                      <RadioLabel htmlFor="fail">{intl.get("test-execution.fail")}</RadioLabel>
                    </RadioInput>
                    <RadioInput>
                      <input
                        type={'checkbox'}
                        id="notExecuting"
                        name="nao-executado"
                        disabled={!notExecResult ? true : false}
                        checked={notExecutedFilter}
                        onClick={() => setNotExecutedFilter(!notExecutedFilter)}
                      />
                      <RadioLabel htmlFor="notExecuting">{intl.get("test-execution.not-executed")}</RadioLabel>
                    </RadioInput>
                    <RadioInput>
                      <input
                        type={'checkbox'}
                        id="notCompleting"
                        name="nao-concluido"
                        disabled={!notConcResult ? true : false}
                        checked={notConcludedFilter}
                        onClick={() => setNotConcludedFilter(!notConcludedFilter)}
                      />
                      <RadioLabel htmlFor="notCompleting">{intl.get("test-execution.not-completed")}</RadioLabel>
                    </RadioInput>
                    <RadioInput>
                      <input
                        type={'checkbox'}
                        id="passed"
                        name="passou"
                        disabled={!notPassResult ? true : false}
                        checked={passedFilter}
                        onClick={() => setPassedFilter(!passedFilter)}
                      />
                      <RadioLabel htmlFor="passed">{intl.get("test-execution.passed-on")}</RadioLabel>
                    </RadioInput>
                    <RadioInput>
                      <input
                        type={'checkbox'}
                        id="notSelected"
                        name="nao-selecionado"
                        checked={notSelectedFilter}
                        onClick={() => setNotSelectedFilter(!notSelectedFilter)}
                      />
                      <RadioLabel htmlFor="notSelected">{intl.get("test-execution.not-selected")}</RadioLabel>
                    </RadioInput>
                  </RadioContainer>
              }
              {json ? <TestTree
                data={filterJson}
                onSelectTreeItem={handleSelectTreeItem}
                execution={execution}
                endExecution={endExecution}
                firstFilter={radioSelection}
                failFilter={failFilter}
                notExecutedFilter={notExecutedFilter}
                notConcludedFilter={notConcludedFilter}
                passedFilter={passedFilter}
                notSelectedFilter={notSelectedFilter}
                getProjectStatus={getProjectStatus}
              /> : <CustomLoader />}
            </TreeContainer>
          </Left>

          <Right id='right-content-container'>
            <SubTitle>{intl.get("test-execution.detailing")}</SubTitle>
            <DetailDiv>
              <CardContainer>
                <>
                  <img src={timer} width={34} />
                </>
                <TextInfo>
                  <h3 style={{ fontSize: 16 }}>
                    <Timer ref={timerComponentRef}></Timer>
                  </h3>
                  <p>{intl.get("test-execution.test-duration")}</p>
                </TextInfo>
              </CardContainer>
              <CardContainer>
                <>
                  <img src={steps} width={34} />
                </>
                <TextInfo>
                  {renderStepsLeft()}
                  <p>{intl.get("test-execution.remaining-steps")}</p>
                </TextInfo>
              </CardContainer>
            </DetailDiv>
            <DetailProgress>
              <BarContainer>
                <Progress
                  animateOnRender={true}
                  baseBgColor={"#fff"}
                  height={'21px'}
                  borderRadius={'20px'}
                  width={!isEdging ? '250px' : '190px'}
                  bgColor="linear-gradient(to right, #330d69, #30c9cd)"
                  completed={getPercent(stepsDoneCounter, endExecution ? lastExecutionDone : stepsSelectedCounter)} />
              </BarContainer>
              <StatusDetail>
                <Info children={`${intl.get("test-execution.progress")}:  ${getPercent(stepsDoneCounter, endExecution ? lastExecutionDone : stepsSelectedCounter)}%`} />
                {endExecution && <ResultReportLabel status={json.executionStatus}>{translateExecutionStatus(json.executionStatus)}</ResultReportLabel>}
              </StatusDetail>
            </DetailProgress>
            <LogContainer>
              {json !== null && execution === true || endExecution === true ?
                <LogCards project={json} endExecution={endExecution} socketData={socketData} executionStatus={executionStatus} /> : <Response />
              }
            </LogContainer>
            <ActionContainer>
              <LogInput
                onSubmit={onSubmitUserResponse}
                type={'text'}
                placeholder={intl.get("test-execution.type-here")}
                value={userConfirm}
                onChange={userResponseHandler}
                styles={{ position: 'relative', bottom: 38 }}
                disabled={!isWaitingInput()}
                bgInput={'transparent'}
                bgIcon={isWaitingInput() ? ' #eff4f8' : '#7e7e7e'}
                color={isWaitingInput() ? '#408bb4' : '#fff'}
                borderColor={isWaitingInput() ? '#408bb4' : '#7e7e7e'}
              />
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '5px', paddingRight: '6px' }}>
                <ButtonActions
                  execButton
                  bgColor={(executionStatus != EXECUTION_STATUS.STS_ALT_CONFIRM_VALUE && executionStatus != EXECUTION_STATUS.STS_ALT_WAIT_INPUT) && playControlButton && hasChecked(filterJson) ? '#2eb6a8' : '#7e7e7e'}
                  style={{ borderRadius: 7 }}
                  children={<FaPlay size={12} style={{ justifyContent: 'center', alignItems: 'center', marginTop: 2 }} />}
                  onClick={playControlButton && (executionStatus != EXECUTION_STATUS.STS_ALT_CONFIRM_VALUE && executionStatus != EXECUTION_STATUS.STS_ALT_WAIT_INPUT) && hasChecked(json) ? onClickPlay : null}
                  disabled={(executionStatus != EXECUTION_STATUS.STS_ALT_CONFIRM_VALUE && executionStatus != EXECUTION_STATUS.STS_ALT_WAIT_INPUT) && playControlButton && hasChecked(filterJson) ? false : true} />
                <ButtonActions
                  execButton
                  bgColor={pauseControlButton ? '#4c97af' : '#7e7e7e'}
                  style={{ borderRadius: 7 }}
                  children={<IoMdPause size={12} style={{ justifyContent: 'center', alignItems: 'center', marginTop: 2 }} />}
                  onClick={pauseControlButton ? onClickPause : null}
                  disabled={!pauseControlButton ? true : false} />
                <ButtonActions
                  execButton
                  bgColor={stopControlButton ? '#1f5780' : '#7e7e7e'}
                  style={{ borderRadius: 7 }}
                  children={<IoStop size={'1.8vh'} style={{ justifyContent: 'center', alignItems: 'center', marginTop: 0.5 }} />}
                  onClick={stopControlButton ? onClickStop : null}
                  disabled={!stopControlButton ? true : false} />
                <ButtonActions
                  bgColor={!getReportDisabled() ? '#1f5780' : '#7e7e7e'}
                  style={{ fontSize: '1.3vh' }}
                  disabled={getReportDisabled()}
                  onClick={() => {
                    setOpenGenerateReport(true)
                  }}
                  text={getReportButtonText()} />
              </div>
            </ActionContainer >
          </Right >
        </Both>
        <Success
          isOpen={openConnection}
          onRequestClose={closeConnectionModal}
          title={intl.get("test-execution.mqtt-devices")}
          message={intl.get("test-execution.device-successfully-connected")} />
        <Success
          isOpen={openDisconnection}
          onRequestClose={closeDisconnectionModal}
          title={intl.get("test-execution.mqtt-devices")}
          message={intl.get("test-execution.device-successfully-disconnected")} />
        <ModalParams
          isOpen={openParamsModal}
          onRequestClose={closeModalParams}
          content={selectedParams}
          update={resultParams}
          error={disableUpd}
          steps={filteredSteps}
          flags={paramFlag}
        />
        <ErrorLog
          isOpen={openError}
          onRequestClose={closeErrorModal}
          title={errorTitle}
          message={errorMsg} />
        <ErrorLog
          isOpen={fetchError}
          onRequestClose={closeErrorFetch}
          title={intl.get('test-execution.fetch-error')}
          message={fetchMsg} />
        <RemoveUpdate
          isOpen={openRemoveParams}
          onRequestClose={closeRemoveParamsModal}
          title={intl.get("test-execution.parameter-setting")}
          message={intl.get("test-execution.do-you-want-to-remove-the-edit-made-to-the-parameter-file")}
          action={deleteUpdParam} />
        <ModalReconnect
          isOpen={openReconnect}
          onRequestClose={closeReconnect}
          title={'Reconexão'}
          message={'Reconectando...'}
          onClick={(e) => { e.preventDefault(); onClickStop(); }}
        />
        <RemoveUpdate
          isOpen={openGenerateReport}
          type={"report"}
          onRequestClose={closeGenerateReportModal}
          title={intl.get("test-execution.report-generation")}
          message={intl.get("test-execution.do-you-want-to-generate-the-report")}
          action={confirmGenerateReport} />
        <Loader isShow={isShowLoader} />
      </Wrapper>
    </Container >
  );
}
